@media (min-width: 1500px) {
    .sidmenu {
        max-width: 15rem;
    }
    .main-content {
        left: 15rem;
        max-width: calc(100% - 15rem);
        min-width: calc(100% - 15rem);
    }
    .module-tab .nav-item .nav-link {
        font-size: 13px;
    }
    .langchart{
        height: 300px;
    }
    .dashboard .cardbox{
        padding: 10px 15px 20px 15px !important;
    }
    .tabsproduct .nav-item .nav-link {
        font-size: 14px;
    }

}
@media (max-width: 1500px) {
    .welcomediv h6 {
        font-size: 1rem;
        text-align: left;
    }
    .stat-box{
        padding: 1rem 0.7rem 0.7rem 1.2rem;
    }
    .stat-box img {
        width: 3.5rem;
        height: 3.5rem;
        padding: 0.65rem;
    }
    .stat-box h6{
        font-size: 0.9rem;
    }
    .stat-box h1{
        font-size: 1.75rem;
    }
    .cardbox {
        border-radius: 5px;
        padding: 7px 10px;
    }
    .groupcardesign .avatarimg, .usercardesign .avatarimg{
        height: 60px;
        max-width: 60px;
    }
    .groupcardesign .groupname, .usercardesign .groupname{
        font-size: 13px;
        padding: 5px 0;
    }
    .groupcardesign p, .usercardesign p{
        font-size: 11px;
        font-weight: 500;
    }
    .select-group, .more-groups{
        font-size: 12px;
        min-width: 5.5rem;
    }
    .standardinput .forselect-input input {
        height: 20px;
        padding: 0px 15px;
    }
    
    .standardinput .forselect-input > div {
        font-size: 0.9rem;
    }
    
    .standardinput .forselect-input input:focus, .standardinput .forselect-input input:focus-visible, .standardinput .forselect-input input:focus-within {
        height: 20px;
      }
    
      .groupcardesign .avatarimg, .usercardesign .avatarimg {
        height: 60px;
        max-width: 60px;
      }
      .datacardesign .cardimages .avatarimg img {
        width: 100%;
        height: 100%;
      }

    .dropbox {
        width: 9rem;
        max-width: 9rem;
    }
    .image-formbox p,.standardinput label p{
        font-size: 14px;
    }
    .standardinput input:focus, .standardinput input:focus-visible, .standardinput input:focus-within,.standardinput input{
        height: 38px;
        font-size: 14px;
    }

    .group-title span p{
        font-size: 14px;
    }

    .footerbtn button.cancel,button.save.loading,.footerbtn button.save {
        height: 35px;
        font-size: 12px;
        }

        .page-title h5 {
            font-size: 1.1rem;
        }
    
        .plain-btn {
            font-size: 0.8rem !important;
            height: 35px !important;
        }
    
        .table thead tr th{
            font-size: 0.85rem;
            padding: 15px 10px;
            font-weight: 500;
        }
        .table tbody tr td{
            font-size: 0.8rem;
        }
        .workflowtb .userimage-small,.workflowtb span.initial-small {
            width: 5rem;
            min-width: 5rem;
            max-width: 5rem;
            aspect-ratio: 1.5 / 1;
        }

        span.initial-small{
            font-size: 1.2rem;
            letter-spacing: 1px;
        }
    
        .qr-code img {
            width: 4rem;
            min-width: 4rem;
            max-width: 4rem;
            object-fit: contain;
        }
        .details-tags.attable span p {
                padding: 1px 6px;
                font-size: 0.8rem;
        }
        .sidmenu {
            max-width: 13rem;
        }
        .main-content {
            left: 13rem;
            max-width: calc(100% - 13rem);
            min-width: calc(100% - 13rem);
        }
        .mainsidelist ul li p {
            font-size: 12px !important;
            font-weight: 500 !important;
        }
        .mainlogo p {
            color: #333;
            font-size: 0.9rem;
        }
        p.pwrdby {
            color: #4b4b4b;
            font-size: .8rem;
        }

        .first-event{
            font-size: 1.1rem;
        }

        .checkbox-apple label , .yep{
            width: 35px;
            height: 18px;
        }
        .checkbox-apple label:after {
            content: '';
            position: absolute;
            top: 0.5px;
            left: 1px;
            width: 16px;
            height: 16px;
        }
        .checkbox-apple input[type="checkbox"]:checked + label:after {
            transform: translateX(18px);
        }

        .module-tab .nav-item .nav-link {
            font-size: 12px;
            font-weight: 500;
            gap: 7px;
            padding: 5px 15px;
        }

        .filterbox .filterhead p{
            font-size: 18px;
        }

        .filterbox .createrhead p{
            font-size: 14px;
        }

        .filterbox .createdatahere label span{
            font-size: 13px;
        }
        .searchfields .form-control{
            height: 35px;
        }
        .searchfields .searchicon{
            top: 5px;
        }

}


@media(max-width:1300px){
    .filterbox{
        min-width: 450px;
    }
}

@media (max-width:767px){
    .rangeselect{
        justify-content: flex-start;
    }
}

@media (max-width:450px){
    .filterbox{
        min-width: 100%;
    }
    .groupcardesign .avatarimg, .usercardesign .avatarimg {
        height: 40px;
        max-width: 40px;
      }
}

@media(min-width:1700px){
    .stat-box h6, .welcomediv h6,.redgreen span:last-child h6{
        font-size: 1.15rem;
    }
    .welcomediv p,.redgreen span p{
        font-size: 1rem;
    }
    .rangetype p{
        font-size: 1rem;
    }
}


/* Responsive Cards CSS Starts */

@media (min-width: 300px){
    .usergrouplist .course-tiles {
        --cols: 1;
    }

    .channels .list-here{
        --cols: 1
    }
    .sideactive .channels .list-here{
        --cols: 1
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}
@media (min-width: 350px){
    .usergrouplist .course-tiles {
        --cols: 1;
    }

    .channels .list-here{
        --cols: 1
    }
    .sideactive .channels .list-here{
        --cols: 2
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}
@media (min-width: 450px){
    .usergrouplist .course-tiles {
        --cols: 1;
    }
    
    .channels .list-here{
        --cols: 1
    }
    .sideactive .channels .list-here{
        --cols: 2
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}
@media (min-width: 600px){
    .usergrouplist .course-tiles {
        --cols: 2;
    }

    .channels .list-here{
        --cols: 2
    }
    .sideactive .channels .list-here{
        --cols: 3
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}
@media (min-width: 850px){
    .usergrouplist .course-tiles {
        --cols: 3;
    }

    .channels .list-here{
        --cols: 3
    }
    .sideactive .channels .list-here{
        --cols: 4
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}
@media (min-width: 1000px){
    .usergrouplist .course-tiles {
        --cols: 4;
    }

    .channels .list-here{
        --cols: 3
    }
    .sideactive .channels .list-here{
        --cols: 4
    }
    .channels .list-here .channel-card {
        max-width: 250px;
    }
}
@media (min-width: 1250px){
    .usergrouplist .course-tiles {
        --cols: 5;
    }

    .channels .list-here{
        --cols: 4
    }
    .sideactive .channels .list-here{
        --cols: 5
    }
    .channels .list-here .channel-card {
        max-width: 250px;
    }
}
@media (min-width: 1400px){
    .channels .list-here{
        --cols: 4
    }
    .sideactive .channels .list-here{
        --cols: 5
    }
    .channels .list-here .channel-card {
        max-width: 315px;
    }
}
@media (min-width: 1750px){
    .usergrouplist .course-tiles {
        --cols: 6;
    }
    
    .channels .list-here{
        --cols: 5
    }
    .sideactive .channels .list-here{
        --cols: 6
    }
    .channels .list-here .channel-card {
        max-width: 300px;
    }

}
@media (min-width: 2000px){
    .usergrouplist .course-tiles {
        --cols: 6;
    }

    .channels .list-here{
        --cols: 6
    }
    .sideactive .channels .list-here{
        --cols: 7
    }
    .channels .list-here .channel-card {
        max-width:320px;
    }
}
@media (min-width: 2300px){
    .usergrouplist .course-tiles {
        --cols: 7;
    }
    
    .channels .list-here{
        --cols: 7
    }
    .sideactive .channels .list-here{
        --cols: 8
    }
    .channels .list-here .channel-card {
        max-width: 100%;
    }
}

/* Responsive Cards CSS Ends */


/* GROUPS TILES CSS STARTS */

@media (max-width: 300px) {
    .course-tiles {
      --cols: 1 ;
    }
    .course-tiles .tilecard {
      max-width: 300px;
    }
    .sideactive .course-tiles {
      --cols: 1 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 280px;
    }
    .usergrouplist .course-tiles {
      --cols: 1 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 1 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }

@media (min-width: 350px) {
    .course-tiles {
      --cols: 1 ;
    }
    .course-tiles .tilecard {
      max-width: 300px;
    }
    .sideactive .course-tiles {
      --cols: 2 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 280px;
    }
    .usergrouplist .course-tiles {
      --cols: 1 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 2 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 450px) {
    .course-tiles {
      --cols: 1 ;
    }
    .course-tiles .tilecard {
      max-width: 300px;
    }
    .sideactive .course-tiles {
      --cols: 2 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 280px;
    }
    .usergrouplist .course-tiles {
      --cols: 1 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 2 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 600px) {
    .course-tiles {
      --cols: 2 ;
    }
    .course-tiles .tilecard {
      max-width: 300px;
    }
    .sideactive .course-tiles {
      --cols: 2 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 280px;
    }
    .usergrouplist .course-tiles {
      --cols: 2 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 3 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 800px) {
    .course-tiles {
      --cols: 2 ;
    }
    .course-tiles .tilecard {
      max-width: 300px;
    }
    .sideactive .course-tiles {
      --cols: 3 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 280px;
    }
  }
  @media (min-width: 850px) {
    .usergrouplist .course-tiles {
      --cols: 3 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 4 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 1000px) {
    .course-tiles {
      --cols: 3 ;
    }
    .sideactive .course-tiles {
      --cols: 4 ;
    }
    .course-tiles .tilecard {
      max-width: 280px;
    }
    .usergrouplist .course-tiles {
      --cols: 4 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 5 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 1250px) {
    .course-tiles {
      --cols: 4 ;
    }
    .sideactive .course-tiles {
      --cols: 4 ;
    }
    .explore-courses .course-tiles {
      --cols: 3 ;
    }
    .explore-courses .home-explore .course-tiles {
      --cols: 4 ;
    }
    .sideactive .course-tiles .tilecard {
      max-width: 320px;
    }
    .usergrouplist .course-tiles {
      --cols: 5 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 5 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
  }
  @media (min-width: 1500px) {
    .course-tiles .tilecard {
      max-width: 350px;
    }
    .explore-courses .course-tiles .tilecard {
      max-width: 350px;
    }
    .sideactive .course-tiles {
      --cols: 5 ;
    }
    .explore-courses .course-tiles {
      --cols: 3 ;
    }
    .explore-courses .home-explore .course-tiles {
      --cols: 4 ;
    }
  }

  @media (min-width: 1750px) {
    .course-tiles .tilecard {
      max-width: 350px;
    }
    .sideactive .course-tiles {
      --cols: 5 ;
    }
    .course-tiles {
      --cols: 5 ;
    }
    .usergrouplist .course-tiles {
      --cols: 6 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 6 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
    .explore-courses .course-tiles {
      --cols: 4 ;
    }
    .explore-courses .home-explore .course-tiles {
      --cols: 5 ;
    }
  }
  @media (min-width: 1850px) {
    .sideactive .course-tiles .tilecard {
      max-width: 350px;
    }
  }

  @media (min-width: 2000px) {
    .sideactive .course-tiles {
      --cols: 6 ;
    }
    .usergrouplist .course-tiles {
      --cols: 6 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 7 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
    .explore-courses .course-tiles {
      --cols: 5 ;
    }
    .explore-courses .home-explore .course-tiles {
      --cols: 6 ;
    }
  }

  @media (min-width: 2300px) {
    .course-tiles {
      --cols: 6 ;
    }
    .usergrouplist .course-tiles {
      --cols: 7 ;
    }
    .sideactive .usergrouplist .course-tiles {
      --cols: 8 ;
    }
    .usergrouplist .course-tiles .tilecard {
      max-width: 100%;
    }
    .explore-courses .course-tiles {
      --cols: 5 ;
    }
    .explore-courses .home-explore .course-tiles {
      --cols: 6 ;
    }
  }



  @media (max-width: 1250px) {
    .groupcardesign p, .usercardesign p {
      font-size: 10px;
    }
    .groupcardesign .groupname, .usercardesign .groupname {
      font-size: 12px;
    }
    .groupcardesign .avatarimg, .usercardesign .avatarimg {
        height: 50px;
        width: 50px;
        max-height: 50px;
        max-width: 50px;
    }
    .groupcardesign .initials-placeholder, .usercardesign .initials-placeholder {
      height: 50px;
      width: 50px;
      max-height: 50px;
      max-width: 50px;
    }
  }

  
  /* GROUPS TILES CSS ENDS */