@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

body.filter-active {
  overflow: hidden;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
}

*::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f3f3f3;
  display: block;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.menu-long::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #55555550;
}

body {
  padding: 0 !important;
  margin: 0 !important;
}

/* *********** */
/* HEADER CSS */
/* *********** */

.sidetoggle,
header {
  align-items: center;
  display: flex;
}

body::-webkit-scrollbar {
  display: none;
}

header {
  align-items: center;
  display: flex;
  background: #fff;
  border-bottom: 2px solid #e3e2e2 !important;
  height: 58px;
  justify-content: space-between;
  padding: 0 12px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.sidetoggle {
  gap: 20px;
  padding-left: 10px;
}

p.error {
  margin: 0;
  color: #ca373c;
  font-weight: 500;
  font-size: 12px !important;
  min-height: 17px;
}

.sidesvgdiv {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sidetoggle svg {
  fill: #000;
  cursor: pointer;
  height: 16px;
  width: 18px;
}

.sidetoggle .activebar #Path_3 {
  -webkit-transform: translate(-10px, -4.523px);
  transform: translate(-10px, -4.523px);
  transition: all 0.3s ease;
}

.sidetoggle #Path_3 {
  transition: all 0.3s ease;
}

.mainlogo {
  align-items: center;
  display: flex;
  justify-content: center;
}

.mainlogo img {
  height: 40px !important;
  object-fit: contain;
  width: 40px !important;
}

.mainlogo p {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  padding-left: 1rem;
  line-height: 2rem;
}

.leftmain {
  gap: 20px;
}

.leftmain,
.profile {
  align-items: center;
  display: flex;
  justify-content: start;
}

.profile {
  gap: 5px;
}

.profileavatar {
  align-items: center;
  display: flex;
  gap: 3px;
}

.profilemenudata p {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.profileavatar p:first-child {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  text-transform: capitalize;
}

.profilepik {
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background-color: #505050;
  border-radius: 50%;
  height: 30px;
  padding: 1px;
  width: 30px;
}

.profileavatar {
  align-items: center;
  display: flex;
  gap: 3px;
}

.profilemenuhead {
  align-items: center;
  border-bottom: 2px solid #70707030;
  display: flex;
  gap: 15px;
  min-width: 300px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 10px;
}

.appnavigator .themetoggle {
  padding-left: 0 !important;
  padding-right: 10px !important;
}

.themetoggle {
  background-color: initial !important;
  border: none !important;
  display: flex !important;
}

.menu-long {
  max-height: 21rem;
  overflow: auto;
  padding: 10px 7px 10px 10px;
}

.appnavigator .themetoggle svg {
  height: 20px;
  width: 20px;
}

.appshere {
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90px;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  width: 90px;
}

.appshere:hover {
  background-color: #f3f3f3;
}

.appshere img {
  height: 30px;
  object-fit: contain;
  width: 30px;
}

.appshere p {
  color: #333 !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 15px !important;
  margin: 0 !important;
  min-height: 30px;
  text-align: center !important;
  display: flex;
  align-items: center;
}

.dropdown-toggle::after {
  content: inherit !important;
}

p.logoutbtn {
  color: #ca373c;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-bottom: 5px;
  padding-top: 8px;
  text-align: center;
  width: 100%;
}

.appnavigator .dropdown-menu.show {
  background-color: #f3f3f3;
  padding: 0px !important;
  min-width: auto;
  overflow: hidden;
}

.appnavigator .dropdown-menu.show .menubg {
  background-color: #fff;
  border-radius: 15px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: max-content;
}

.appnavigator .dropdown-menu.show .menubg.extraspace {
  max-width: 320px;
}

.profilepik {
  border: none;
  box-shadow: 0 3px 6px #00000029;
  background-color: #505050;
  border-radius: 50%;
  height: 30px;
  padding: 1px;
  width: 30px;
}

.avatar-placeholder {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50px;
  text-align: center;
  background-color: #505050;
  padding: 1px;
  margin-left: 5px;
  -o-object-fit: contain;
  object-fit: contain;
  color: #fff;
}

/* *********** */
/* SIDEBAR CSS */
/* *********** */

.sidmenu {
  background-color: #fff;
  border-right: 2px solid #e3e2e2 !important;
  max-width: 14rem;
  min-height: calc(100% - 58px);
  overflow-x: hidden;
  position: fixed;
  top: 58px;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  width: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 1rem; */
}

.mainsidelist {
  padding: 1rem 0 1rem;
}

.mainsidelist ul {
  list-style-type: none;
  padding: 0;
}

.mainsidelist ul li {
  align-items: center !important;
  border-radius: 5px;
  color: #000;
  cursor: pointer;
  display: flex !important;
  gap: 0;
  justify-content: start !important;
  margin: 4px 0;
  padding: 5px 0 5px 10px;
  position: relative;
  width: 96%;
}

.mainsidelist ul li.active {
  background-color: #ca373c;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff !important;
}

.mainsidelist ul li svg {
  fill: #000;
  height: 16px;
  margin-left: 15px;
  width: 16px;
}

.mainsidelist ul li.active svg {
  fill: #fff;
}

.mainsidelist ul li p {
  text-wrap: nowrap;
  color: #333 !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.mainsidelist ul li.active p {
  color: #fff !important;
}

.mainsidelist ul li svg path {
  fill: #000;
}

.mainsidelist ul li.active svg path {
  fill: #fff;
}

.sidmenu.active {
  left: -10px;
  max-width: 0;
  min-height: calc(100% - 63px);
  min-width: 0;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.mainsidelist ul li:hover {
  background-color: #ca373c50;
}

p.pwrdby {
  color: #4b4b4b;
  font-size: 0.85rem;
  margin: 0;
  padding-bottom: 0.5rem;
  padding-left: 5%;
  padding-top: 0.5rem;
  text-align: left;
  width: 100%;
}

p.pwrdby,
p.pwrdby strong {
  align-items: center;
  display: flex;
  justify-content: start;
}

p.pwrdby strong {
  font-weight: 600;
  gap: 5px;
}

p.pwrdby strong img {
  filter: grayscale(4);
  margin-left: 0.3rem;
  opacity: 0.95;
  width: 1rem;
}

/* *********** */
/* MAINBODY CSS */
/* *********** */

.main-content {
  background-color: initial;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24);
  left: 14rem;
  max-width: calc(100% - 14rem);
  min-height: calc(100vh - 58px);
  min-width: calc(100% - 14rem);
  padding: 0;
  position: relative;
  top: 58px;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  background-color: #f3f3f3;
}

.main-content.sideactive {
  left: 0;
  min-width: 100%;
  transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.main-content.editorhere {
  left: 0;
}

/* Machines page */

.machines-list {
  padding-top: 1.5rem;
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1.5rem;
  height: 35px;
}

.page-title p {
  margin: 0;
}

.first-event {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.35rem;
  font-weight: 500;
  gap: 1rem;
  justify-content: center;
  min-height: 50vh;
  min-width: 50vw;
}

.pag-tag {
  color: #ca373c;
  font-weight: 500;
  font-size: 1rem;
  margin: 0;
}

.data-card-board {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03);
}

.usercardesign {
  align-items: center;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin-bottom: 15px;
  text-align: center;
  transition: 0.3s;
}

.usercardesign .initials-placeholder {
  align-items: center;
  background: rgba(223, 225, 235, 0.4);
  border-radius: 5px;
  display: flex;
  font-size: 20px;
  font-weight: 500;
  height: 65px;
  justify-content: center;
  letter-spacing: 1px;
  border: 1px solid #fff;
  margin: 0 auto;
  /* outline: 1px solid rgba(44, 44, 44, .314) !important; */
  outline-offset: 0;
  overflow: hidden;
  padding: 3px;
  position: relative;
  width: 65px;
}

.data-card-board h4 {
  font-size: 1.2rem;
  margin: 0;
  padding-left: 0rem;
}

.name-edit {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.3rem;
}

.name-edit > span {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  transition: 300ms;
  border-radius: 5px;
  margin-top: -3px;
}

.name-edit > span:hover {
  background-color: #ca373c30;
}

.name-edit > span svg {
  width: 100%;
  height: 100%;
  padding: 4px;
}

.stats-here {
  display: flex;
  gap: 30px;
  justify-content: end;
  padding-right: 30px;
}

.stats-here .stat-item {
  border-left: 2px solid #33333330;
  padding-left: 30px;
  margin: 10px 0;
}

.stats-here .stat-item p {
  color: #00000070;
  font-weight: 500;
  margin-bottom: 0;
  font-size: 15px;
}

.stats-here .stat-item h5 {
  font-size: 17px;
  padding-bottom: 20px;
  padding-top: 5px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}

/* BUttons */

.plain-btn {
  background-color: #ca373c;
  border: none;
  padding: 0rem 1rem;
  border-radius: 5px;
  color: #fff;
  font-size: 0.85rem;
  height: 38px;
}

.plain-btn.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  padding: 0 0.7rem;
}

.plain-btn.icon svg {
  width: 1.45rem;
  height: 1.45rem;
}

.backbtn {
  border: none;
  border-radius: 5px;
  color: #333;
  cursor: pointer;
  font-size: 13px;
  gap: 5px;
  /* padding: 3px 0; */
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
  background-color: #33333310;
  padding-right: 0.5rem;
}

.backbtn svg {
  fill: #333;
  height: 0.9em;
  width: 0.9em;
}

/* table */
.table thead tr {
  background-color: #ffc8ca;
}

.table thead tr th {
  background-color: rgba(202, 55, 60, 0);
  border: none;
  font-weight: 500;
  font-size: 0.95rem;
  padding: 15px 10px;
  color: #333;
}

.table tbody tr td {
  background-color: rgba(202, 55, 60, 0);
  border: none;
  font-weight: 500;
  font-size: 0.9rem;
  padding: 15px 10px;
  color: #333;
}

.table tbody tr {
  background-color: #fff;
  border-bottom: 1px solid #4d4d4d25;
}

.table tbody tr:hover {
  background-color: #4d4d4d20;
}

.machinelist .table tbody tr td a {
  color: #333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.table tbody tr:hover td a {
  color: #0d6efd;
  /* text-decoration: underline; */
}

.qr-code {
  position: relative;
  width: 5rem;
}

.qr-code img {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
  object-fit: contain;
}

.qr-code:hover .qr-options {
  display: flex;
}

.qr-options {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #00000090;
  display: none;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}

.qr-options span {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 3px 5px !important;
  background-color: #00000099;
}

.qr-options span svg {
  height: 17px;
  width: 17px;
  fill: #fff;
}

.action-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.action-btns span {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin: 0;
  padding: 3px 5px !important;
}

.action-btns span svg {
  height: 17px;
  width: 17px;
  fill: #fff;
}

.action-btns span:hover {
  background-color: #ca373c50;
}

.action-btns span.eye svg path {
  fill: #333;
}

.prime-select {
  width: auto;
}

.tags-sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.prime-select .p-multiselect-label,
.prime-select .p-dropdown-label {
  padding: 0.53rem;
  font-size: 14px;
}

.prime-select .p-multiselect-label,
.prime-select .p-dropdown-label {
  padding: 0.53rem;
  font-size: 14px;
  border-color: #ca373c00;
  box-shadow: 0 0 0 0.2rem #ca373c00;
}

.prime-select .p-multiselect-label {
  padding-right: 1rem;
}

.p-dropdown:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-multiselect:not(.p-disabled):hover {
  border-color: #ca373c00;
  box-shadow: 0 0 0 0.2rem #ca373c00;
}

/* .prime-select .p-multiselect-label {
  width: 12rem;
} */

.p-dropdown-panel .p-dropdown-items {
  margin: 0;
  padding: 0;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ca373c;
  background: #ca373c20;
  font-size: 0.9rem;
  padding: 0.5rem 1rem;
}

.app-search svg {
  color: #a4a3a3;
  left: 25px;
}

.app-search .searchiconcancel svg,
.app-search svg {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 0;
}

.app-search .form-control {
  background: #fff 0 0 no-repeat padding-box;
  border: 1px solid #e3e2e2;
  border-radius: 5px;
  box-shadow: none;
  font-size: 14px;
  height: 38px;
  padding-left: 45px;
  padding-right: 20px;
  width: 300px;
}

.app-search .form-control:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707080;
  color: #333333;
  box-shadow: none;
}

.page-title h5 {
  margin: 0;
  font-size: 1.1rem;
}

.page-title span {
  color: #ca353c;
}

.p-dropdown {
  border: none;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
}

/* Modals */

.modalheader {
  background-color: #ca373c;
  color: #fff;
  justify-content: space-between;
}

.modalheader span {
  cursor: pointer;
}

.modalheader span svg {
  width: 1.3rem;
  height: 1.3rem;
}

.footerbtn {
  border: none;
  display: flex;
  align-items: center;
  justify-content: end;
}

.footerbtn.csv-modal {
  justify-content: space-between;
}

.footerbtn button.cancel {
  border: 1px solid #cad1da;
  font-weight: 400;
  background: transparent;
  border-radius: 5px;
  padding: 0px 20px;
  font-size: 13px;
  color: #333333;
  margin: 5px;
  height: 40px;
  width: 6rem;
}

.footerbtn button.save {
  border: 1px solid #cad1da;
  font-weight: 400;
  background: #ca373c;
  border-radius: 5px;
  padding: 0px 20px;
  font-size: 13px;
  color: #fff;
  margin: 5px;
  height: 40px;
  width: 6rem;
}

button.save.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  cursor: not-allowed;
  width: 6rem;
}

button.save.loading svg {
  width: 2em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

button.save.loading circle {
  fill: none;
  stroke: #fff;
  stroke-width: 4;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

.template-down {
  color: #4d4d4d;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}

.popupheader.delete {
  background-color: #fff;
  border-bottom: none;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  justify-content: center;
  text-align: center;
}

.popupheading.delete {
  opacity: 70%;
  text-align: center;
}

.popupheading {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 5px !important;
}

.attachments {
  width: 100%;
}

.display-section {
  display: flex;
  align-items: center;
  justify-content: start;
}

.display-section .Nav-side {
  width: 5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100%;
  max-height: 70vh;
  overflow: auto;
  margin-right: 0rem;
}

.display-section .Nav-side .option {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  border: 1px solid #4d4d4d50;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

.display-section .Nav-side .option.active {
  background-color: #ca373c40;
  border-color: #ca373c;
}

.display-section .Nav-side .option img {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3px;
}

.display-section .Nav-side .option svg {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3px;
  fill: #ca353c;
}

.display-section .Nav-side .option svg path {
  stroke: #ca353c;
}

.display-section .Nav-side .option span {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3px;
  background-color: #ca373c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
}

.display-section .Nav-side .option span svg path {
  stroke: #fff;
  stroke-width: 1.5px;
}

.display-section .Nav-side .option p {
  margin: 0;
  font-size: 0.9rem;
  color: #000;
  font-weight: 500;
}

.display-section .Nav-side .option.active p {
  color: #ca373c;
}

.display-section .Output-side {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.image-view {
  aspect-ratio: 1.5 / 1;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Output-side .image-view img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.video-view {
  aspect-ratio: 1.5 / 1;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-left: 1rem;
}

.pdf-view {
  aspect-ratio: 1.5 / 1;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem;
}

/* INPUT BAR */

.standardinput label {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.standardinput input {
  margin-top: 0.5rem;
  border: 1.5px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 0 20px -18px;
  height: 40px;
  margin-top: 10px;
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

.standardinput input:focus,
.standardinput input:focus-visible,
.standardinput input:focus-within {
  border: 1.5px solid #70707090;
  border-radius: 5px;
  background: #f8f8fa 0% 0% no-repeat padding-box;
  box-shadow: 0 0 20px -18px;
  height: 40px;
  /* margin-top: 10px; */
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
}

/* TABS AND STUFF */

.tabanddown {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.module-tab .nav-item {
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
  margin-left: 0;
}

.module-tab .nav-item .nav-link {
  align-items: center;
  background: #fff 0 0 no-repeat padding-box;
  border: 2px solid transparent;
  color: #333;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  gap: 12px;
  height: 100%;
  justify-content: center;
  padding: 10px 20px;
}

.module-tab .nav-item .nav-link.active {
  border: 2px solid #ca373c;
  color: #ca373c;
}

.module-tab .nav-item .nav-link svg {
  height: 1.15em;
  width: 1.15em;
}
.module-tab .nav-item .nav-link.active svg,
.module-tab .nav-item .nav-link.active svg path {
  fill: #ca373c;
}

/* Checklist */
.checklist {
  padding: 0;
}

.list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list-head h3 {
  font-size: 1rem;
}

.list-body {
  /* background-color: #F2F3F9 !important; */
  border-radius: 10px;
  padding: 10px;
}

.quebodybg {
  background-color: #f2f3f9 !important;
  border-radius: 10px;
}

.questionbox {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #cad1da;
  border-radius: 10px;
  opacity: 1;
  font-weight: 500;
  padding: 10px 15px;
  font-size: 0.9rem;
}

.listchild.form-check {
  padding: 0px 0px 0px 0em;
  margin: 0;
}

.form-check.listchild {
  display: flex;
  align-items: center;
  gap: 10px;
}

.form-check.listchild {
  cursor: pointer;
}

.channelselect .listchild.form-check {
  padding-left: 0em;
}

.listchild.form-check .form-check-input {
  border-color: #ca373c;
  cursor: pointer;
}

.form-check.listchild .form-check-input {
  margin: 0 !important;
}

.listchild.form-check label {
  font-size: 14px;
}

.channelselect .listchild.form-check label {
  font-weight: 500;
  display: flex;
  gap: 15px;
  align-items: center;
}

.svgbtn {
  height: 30px;
  padding: 3px 5px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
}

.svgbtn svg {
  width: 17px;
  height: 17px;
}

.listchild.form-check .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem #ca373c38;
}

.listchild.form-check .form-check-input:checked {
  background-color: #ca373c;
}

.tabanddown.atmodal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: self-start;
}

.tabanddown.atmodal p {
  font-weight: 500;
  color: #000;
  margin: 0;
  padding-bottom: 0.5rem;
}

.tabanddown.atmodal .module-tab .nav-item .nav-link {
  border: 1px solid #ca373c;
  color: #ca373c;
  padding: 10px 15px;
}

.tabanddown.atmodal .module-tab .nav-item .nav-link.active {
  background-color: #ca373c;
  color: #fff;
}

.tabanddown.atmodal .module-tab .nav-item {
  margin: 0;
  margin-right: 10px;
}

.svgbtn:hover,
.redbgbtn:hover {
  background-color: #ca373c50;
}

/* Toggle box */

.checkbox-apple {
  position: relative;
  width: 40px;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox-apple label {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 50px;
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkbox-apple label:after {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
}

.checkbox-apple input[type="checkbox"]:checked + label {
  background: linear-gradient(to bottom, #4cd964, #5de24e);
}

.checkbox-apple input[type="checkbox"]:checked + label:after {
  transform: translateX(20px);
}

.checkbox-apple label:hover {
  background: linear-gradient(to bottom, #b3b3b3, #e6e6e6);
}

.checkbox-apple label:hover:after {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.yep {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 20px;
}

.details-tags {
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 0;
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: capitalize;
    color: #4d4d4d;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
    p {
      margin: 0;
      padding: 0 8px;
      background-color: #ca373c20;
      color: #ca373c;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: 500;
    }
  }
}

.details-tags.attable {
  align-items: center;
}

.details-tags.attable span {
  max-width: 8rem;
  flex-wrap: wrap;
  justify-content: center;
  p {
    padding: 3px 8px;
    font-size: 0.9rem;
  }
}

.standardinput label p {
  margin: 0;
}

.standardinput label p span {
  color: #ca373c;
}

.selectinput p {
  color: #000;
  font-weight: 500;
  margin: 0;
  font-size: 15px;
}

.nameemail {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  border: 0.5px solid #4d4d4d10;
  border-radius: 19px;
  padding-left: 0px;
  padding-right: 10px;
  background-color: #4d4d4d05;
}

.nameemail .avatar-placeholder {
  margin-left: 0;
}

table .nameemail {
  padding-right: 15px;
  width: fit-content;
}
.nameemail img {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  object-fit: contain;
  padding: 3px;
}

.nameemail p {
  margin: 0;
  font-size: 0.9rem;
}

.nameemail p:last-child {
  font-size: 0.8rem;
}

.datetime {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.datetime p {
  margin: 0;
  font-size: 1rem;
}

.datetime p:last-child {
  font-size: 0.9rem;
}

.good {
  font-weight: 600;
  color: green;
  font-size: 1rem;
}

.bad {
  font-weight: 600;
  color: #ca373c;
  font-size: 1rem;
}

.breadcrums {
  font-size: 1rem;
}

.breadcrums span {
  font-weight: 500;
  color: #333;
}

.breadcrums span svg {
  stroke: #33333350;
  width: 1.2rem;
  height: 1.2rem;
  margin-top: -2px;
}

.breadcrums span svg path {
  fill: #333;
}

.breadcrumb-link {
  cursor: pointer;
}

.breadcrums span:last-child {
  font-weight: 500;
  color: #ca373c;
}

.table tbody tr td.good {
  background-color: #00800038;
}

.table tbody tr td.bad {
  background-color: #ca373c33;
}

.rangeselect {
  display: flex;
  align-items: center;
  justify-content: end;
  width: fit-content;
  gap: 20px;
  padding-right: 10px;
}

.rangetype p {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #8d8d8d;
  cursor: pointer;
  padding: 0 2px;
}

.rangetype.active p {
  color: #505050;
  border-bottom: 2px solid #ca373c;
}

.welcomecard .card-block {
  height: 100px;
  padding: 20px;
}

.avatarimg,
.cardesign img {
  /* border-radius: 50%; */
  display: block;
  margin: 0 auto;
}

.cardesign .profile-img {
  height: 75px;
  line-height: 75px;
  object-fit: contain;
  padding: 10px;
  width: 75px;
  max-width: 75px;
  max-height: 75px;
  border-radius: 50%;
  background-color: #e6e6e6;
}

.cardesign img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cardesign h6 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #363636;
  display: block;
  font-size: 15px;
  margin: 8px auto -1px;
  overflow: hidden;
  padding: 5px;
  text-align: center;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  width: 100%;
  font-weight: 600;
}

.initials-placeholder {
  color: #7c7c7c;
  display: block;
  font-size: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.welcomediv h6 {
  font-size: 17px;
  text-align: left;
}

.cardesign p {
  color: #6b6b6b;
  font-size: 12px;
  margin-bottom: 0;
  text-align: center;
}

.welcomediv p {
  font-size: 14px;
  text-align: left;
}

.welcomecard {
  background-color: #fff !important;
}
.card {
  border: none !important;
}

.redgreen {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  gap: 3rem;
}

.redgreen span h6 {
  font-size: 17px;
  text-align: left;
  font-weight: 600;
  /* color: #06d001; */
  margin: 0.5rem;
  margin-left: 0;
}

.redgreen span:last-child h6 {
  font-size: 17px;
  text-align: left;
  font-weight: 600;
  /* color: red; */
  margin: 0.5rem;
  margin-left: 0;
}

.redgreen span p {
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  color: #444;
}

.stats-parent {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  margin-top: 1rem;
  max-width: 100%;
  overflow-x: auto;
}

.stats-parent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 5px;
  background-color: #f5f5f5;
}

.stats-parent::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  height: 5px;
}

.stats-parent::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  background-color: #ca353a82;
}

.stat-box {
  background: #fff;
  padding: 1rem 1rem;
  padding-right: 3rem;
  padding-left: 2rem;
  border-radius: 5px;
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 1rem;
  width: 100%;
  /* flex-direction: column; */
}

.stat-box img {
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  background-color: #e6e6e6;
  border-radius: 5px;
}

.stat-box h6 {
  margin-top: 0.2rem;
  font-size: 1rem;
}

.stat-box h1 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 0.2rem;
}

.stat-box span {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.2rem;
  font-size: 0.85rem;
  margin: 0;
  font-weight: 400;
}

.stat-box span.true,
.stat-box span.true svg {
  color: green;
  fill: green;
}

.stat-box span.false,
.stat-box span.false svg {
  color: red;
  fill: red;
}

.stat-box span svg {
  width: 0.7rem;
  height: 0.7rem;
}

.chart-box {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-top: 1rem;
}

.chart-box p {
  font-size: 1rem;
  margin: 0;
  padding-bottom: 0.5rem;
  font-weight: 500;
}

/* Filter */

.filteroptions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.filteroption span {
  align-items: center;
  background: #ca373c;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 5px;
  justify-content: center;
  padding: 7px 10px;
  width: -webkit-max-content;
  width: max-content;
  /* height: 38px; */
}

.filteroption span svg {
  color: #fff;
  cursor: pointer;
  margin-top: -2px;
  padding-right: 5px;
  width: 20px;
}

.filteroption span.numberhere {
  width: 26px;
  height: 26px;
  background-color: #333;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  padding: 2px;
  font-size: 12px;
}

.filteroption {
  margin-top: -1px;
}

.filterbox.inactive {
  right: -450px;
  transition: 0.3s all ease;
}
.filterbox {
  background: #faebd7;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.161);
  height: auto;
  padding: 10px;
  position: fixed;
  right: 0;
  top: 0;
  max-width: 450px;
  width: 30vw;
  z-index: 10;
  height: 100vh;
  transition: 0.3s all ease;
}

.filterbox .filterhead {
  display: flex;
  justify-content: space-between;
}

.filterbox .filterhead p {
  color: #333;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.filterbox .filterhead p:first-child {
  border-bottom: 2px solid #333;
  color: #333;
  font-weight: 500;
}

.filterhead button {
  background-color: initial;
  border: none;
  border-radius: 5px;
  color: #ca373c;
  font-size: 16px;
  padding: 2px 5px;
}

.filterbox .createdfilter {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  flex-direction: column;
  height: 30px;
  justify-content: start;
  margin-top: 20px;
  overflow: hidden;
  padding-bottom: 10px;
  transition: all 0.4s ease-in-out;
}

.filterbox .createdfilter.createdactive {
  height: 225px;
  max-height: 230px;
  transition: all 0.4s ease-in-out;
}

.filterbox .createrhead {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.filterbox .createrhead p {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.filterbox .createdfilter svg {
  fill: #ca373c;
  height: 20px;
  width: 20px;
}

.filterbox .createrhead p svg {
  fill: #ca373c;
  width: 1em;
  height: 1em;
}

.showoverlay {
  background-color: #00000070;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.filterbox .createdatahere {
  /* max-height: 170px; */
  overflow-y: auto;
  padding: 0;
}

.filterbox .createdatahere::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f3f3f3;
}

.filterbox .createdatahere::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f3f3f3;
  display: block;
}

.filterbox .createdatahere::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.filterbox .search input {
  height: 40px;
}

.filterbox .createdatahere label span {
  font-size: 13px;
  padding: 2px 0 2px 15px;
}

.filterbox .createdatahere label span {
  font-size: 15px;
  font-weight: 400;
  padding: 2px 0 2px 15px;
  font-family: "Poppins", sans-serif;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterbox .searchfields .searchicon svg g {
  opacity: 1;
}

.filterbox .searchfields .searchicon svg path {
  fill: #ca373c;
}

.filterbox .createdatahere label span:nth-child(2) {
  padding-left: 5px;
}

.count {
  width: 20px;
  height: 20px;
  background-color: #ca373c;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  border-radius: 50%;
}

.searchfields .searchicon {
  position: absolute;
  top: 7px;
  left: 10px;
}
.searchfields .searchicon svg {
  width: 17px;
  height: 17px;
}
.searchfields .searchiconcancel {
  position: absolute;
  top: 7px;
  right: 10px;
}
.searchfields .searchiconcancel svg {
  width: 17px;
  height: 17px;
}
.searchfields .form-control {
  padding-left: 36px;
  height: 40px;
  font-size: 0.9rem;
}
.searchfields .form-control:focus {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}
.searchfields .form-control:focus-visible {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}
.searchfields .form-control:focus-within {
  box-shadow: none;
  border-color: rgba(51, 51, 51, 0.3137254902);
}

.MultiNestedSelect {
  width: 300px;
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.MultiNestedSelect ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.MultiNestedSelect li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.MultiNestedSelect li:hover {
  background-color: #f0f0f0;
}

.MultiNestedSelect li:last-child {
  border-bottom: none;
}

.filter-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.standardinput .forselect-input input {
  margin-top: 0.5rem;
  border: 1.5px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 0 20px -18px;
  height: 28px;
  margin-top: 10px;
  outline: none;
  padding: 5px 15px;
  transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 0.5rem;
}

.standardinput .forselect-input > div {
  font-size: 0.9rem;
}

.standardinput .forselect-input input:focus,
.standardinput .forselect-input input:focus-visible,
.standardinput .forselect-input input:focus-within {
  height: 28px;
}

.dropzone-csv {
  width: 100%;
  background-color: #4d4d4d20;
  border: 2px dashed #4d4d4d50;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
  transition: 250ms all;
}

.dropzone-csv:hover {
  cursor: pointer;
  background-color: #4d4d4d40;
  border: 2px dashed #4d4d4d90;
}

.dropzone-csv span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.dropzone-csv span svg {
  width: 3rem;
  height: 3rem;
}

.dropzone-csv span p {
  margin: 0;
  font-weight: 500;
}

.csv-info {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.csv-info p {
  font-weight: 500;
  color: #000;
  margin: 0;
}

.csv-info p span {
  color: #ca373c;
}

.Image-title span.initial-small,
.machine-name span.initial-small {
  width: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  aspect-ratio: 1 / 1;
  background-color: rgba(223, 225, 235, 0.4);
  color: #7c7c7c;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 5px;
  border: 1px solid #fff;
}

.avatarimg span.initial-small {
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  color: #7c7c7c;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.userimage-small {
  object-fit: contain;
  width: 7.5rem;
  min-width: 7.5rem;
  max-width: 7.5rem;
  aspect-ratio: 1.45 / 1;
  background-color: rgba(223, 225, 235, 0.4);
  color: #7c7c7c;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  justify-content: center;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #fff;
}

.workflowtb .userimage-small {
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  aspect-ratio: 1.5 / 1;
}

.workflowtb span.initial-small {
  width: 6rem;
  min-width: 6rem;
  max-width: 6rem;
  aspect-ratio: 1.5 / 1;
}

tr:hover a span.initial-small {
  text-decoration: none;
}

.image-formbox {
  display: flex;
  gap: 1rem;
}

.image-formbox p {
  margin: 0;
  font-weight: 500;
  color: #000;
  font-size: 15px;
}

.image-formbox p.little-info {
  font-size: 13px;
  padding-top: 0.5rem;
  color: #ca373c;
}

.dropbox {
  width: 10rem;
  max-width: 10rem;
  aspect-ratio: 1/0.6;
  /* height: 200px; */
  background-color: #f5f5f5;
  border: 1px dashed #c1c1c1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: border 0.3s ease-in-out;
}
.dropbox:hover {
  border-color: #888;
}

.uploaded-container {
  position: relative;
  width: 10rem;
  aspect-ratio: 1/0.6;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.cancel-button {
  position: absolute;
  top: 1%;
  right: 1%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #ca373c;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
.cancel-button svg {
  width: 100%;
  height: 100%;
}
.cancel-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.placeholder {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-color: rgba(51, 51, 51, 0.2509803922);
}
.placeholder svg {
  width: 3.5rem;
  height: 3.5rem;
}
.placeholder svg path {
  fill: #333;
}

p.error {
  color: #ca373c;
}

.app-search .searchiconcancel svg {
  color: #00000080;
  cursor: pointer;
  height: 1.3em;
  left: auto;
  right: -0px;
  width: 1.3em;
}

.Lottie-parent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent !important;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.Lottie-parent span {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent !important;
  margin-top: -1rem;
}

.Lottie-parent span h5 {
  font-weight: 500;
  color: #333;
}

.Lottie-parent span p {
  font-weight: 500;
  color: #333;
  font-size: 0.9rem;
  max-width: 16rem;
  text-align: center;
}

.lottie-gif {
  width: 10vw;
  background-color: transparent !important;
}

.workflowtb {
  min-height: 400px;
}

/* GROUPS */

p.list-tag {
  text-align: left;
  font-weight: 600;
  color: #333;
  font-size: 1rem;
}

.usergrouplist .course-tiles .tilecard {
  max-width: 100%;
}

.usergrouplist .course-tiles .tilecard .groupcardesign,
.usergrouplist .course-tiles .tilecard .usercardesign {
  width: 100%;
  height: 100%;
  margin: 0;
  aspect-ratio: 1 / 1;
}

.course-tiles {
  display: grid;
  gap: 1rem;
  --u_rg: 1.5rem;
  --u_cg: 1.5rem;
  grid-template-columns: repeat(var(--cols), 1fr);
  justify-items: stretch;
  justify-content: center;
  align-content: center;
  --cols: 1;
}

.tilecard .groupcardesign,
.tilecard .usercardesign {
  align-items: center;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #e8e8ff;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 200px;
  justify-content: center;
  margin-bottom: 15px;
  text-align: center;
  transition: 0.3s;
}

.selected-group .groupcardesign,
.selected-group .usercardesign,
.groupcardesign:hover {
  border-color: #ca373c;
}

.groupcardesign .avatarimg,
.usercardesign .avatarimg {
  background: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  height: 70px;
  max-width: 70px;
  outline: 1px solid rgba(44, 44, 44, 0.314) !important;
  outline-offset: 0px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}

.groupcardesign .avatarimg img,
.usercardesign .avatarimg img {
  height: 100%;
  object-fit: contain;
  padding: 0px;
  width: 100%;
}

.groupcardesign .groupname,
.usercardesign .groupname {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
  text-overflow: ellipsis;
  margin: 0;
  color: #333;
  width: 85%;
  padding: 10px 0;
}

.groupcardesign .groupusercount,
.usercardesign .groupusercount {
  display: flex;
  justify-content: center;
}

.groupcardesign p,
.usercardesign p {
  font-size: 13px;
  margin-bottom: 0;
  text-align: center;
  color: #66666e;
}

/* .tilecard .groupcardesign .initials-placeholder, .tilecard  .usercardesign .initials-placeholder, .tilecard .moudlendgroup .initials-placeholder {
        background: rgba(223, 225, 235, 0.4);
        border-radius: 50%;
        height: 70px;
        width: 70px;
        max-height: 70px;
        max-width: 70px;
        outline: 1px solid rgba(44, 44, 44, 0.314) !important;
        outline-offset: 0px;
        overflow: hidden;
        padding: 3px;
        position: relative;
        margin: 0 auto;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    } */

.select-group {
  font-size: 0.8rem;
  background-color: #ca353b20;
  padding: 2px 10px;
  color: #ca373c;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 0.7rem;
  cursor: pointer;
  transition: 250ms all;
  min-width: 6rem;
}

.select-group:hover {
  background-color: #ca353b;
  color: #fff;
}

.more-groups {
  font-size: 0.8rem;
  background-color: #313b792b;
  padding: 2px 10px;
  color: #313b79;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 0.7rem;
  cursor: pointer;
  transition: 250ms all;
  min-width: 6rem;
}

.more-groups:hover {
  background-color: #313b79;
  color: #fff;
}

.selected-group .select-group {
  background-color: #ca353c;
  color: #fff;
}

.group-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.group-title span {
  display: flex;
  align-items: center;
  justify-content: start;
}

.group-title span p {
  margin: 0;
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 500;
}

span.info-stats {
  display: flex;
  font-size: 0.85rem;
  color: #000;
  font-weight: 500;
}

span.info-stats p {
  background-color: transparent;
  color: #ca353c;
  padding: 0;
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 500;
}

.info-title-image {
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 0.5rem;
  gap: 1rem;
}

.info-title-image.for-details {
  height: 100%;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}

.info-title-image.for-details .info-stats {
  font-weight: 500;
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}

.Image-title {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 0.7rem;
  margin-top: 0.25rem;
}

.Image-title h6 {
  font-weight: 500;
  color: #000;
  font-size: 0.9rem;
  text-align: center;
}

.machine-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

.machine-stats span {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.machine-stats span p {
  font-size: 0.9rem;
  font-weight: 500;
  color: #4d4d4d;
  margin: 0;
}

.machine-stats span h5 {
  font-size: 1rem;
  margin: 0;
  font-weight: 600;
}

.info-title-image .userimage-small {
  width: 10rem;
  max-width: 10rem;
  min-width: 10rem;
  aspect-ratio: 16/9;
  object-fit: contain;
}

.info-title-image span.initial-small {
  width: 10rem;
  max-width: 10rem;
  min-width: 10rem;
  aspect-ratio: 16/9;
  font-size: 1.5rem;
}

.details-tags {
  font-weight: 500;
  font-size: 0.9rem;
  color: #000;
  gap: 0.5rem;
}

.details-tags.main {
  justify-content: space-around;
  height: 100%;
}

.name-edit .m-name {
  font-weight: 500;
  color: #000;
  font-size: 0.9rem;
  text-align: center;
  margin: 0;
}

.machinelist .table tbody tr td.machine-name a {
  max-width: 25rem;
}

.breadcrums {
  display: flex;
  align-items: center;
  justify-content: start;
}

.breadcrumb-link {
  padding: 0 0.3rem !important;
}

.selected-groups {
  align-items: center;
  border-top: 1px solid #33333315;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  margin-top: 0 !important;
  padding: 13px 10px 10px !important;
}

.selected-groups p {
  font-size: 14px;
  margin: 0;
  font-weight: 500;
}

.selected-groups span.group-id {
  align-items: center;
  border: 1px solid #ca373c50;
  border-radius: 4px;
  display: flex;
  font-size: 13px;
  font-weight: 400;
  gap: 5px;
  justify-content: center;
  padding: 2px 7px;
  color: #fff;
  background-color: #ca353c;
}

.selected-groups span.group-id svg {
  fill: #fff;
  cursor: pointer;
  height: 1.5em;
  width: 1.5em;
}

.group-parent .tile-parent {
  max-height: 30rem;
  overflow: auto;
  padding: 0.5rem 0.3rem;
  margin: 0.5rem 0;
  margin-bottom: 0;
}

.breadcrumb-link:hover {
  color: #ca353c;
}

.breadcrumb-link.last {
  color: #ca353c;
}

.tooltip-parent {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  gap: 0.5rem;
  max-width: 100%;
  padding: 0.5rem 0.25rem;
}

.tooltip-parent {
  max-height: 200px;
  overflow: auto;
  max-width: 100%;
}

.info-stats .nameemail {
  padding-left: 0;
}

.info-stats .nameemail img,
.tooltip-parent .nameemail img {
  background-color: #4d4d4d20;
}

.tooltip-parent .nameemail {
  max-width: 100%;
  background-color: transparent;
  border: none;
  padding-left: 0;
}

.tooltip-parent .nameemail p {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

span.info-stats p.extra-item {
  border: 1px solid #ca353c;
  background-color: #ca353c05;
  border-radius: 50%;
  width: 1.9rem;
  height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 0.85rem;
}

span.initial-small.extra {
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  max-height: 2rem;
  max-width: 2rem;
  object-fit: contain;
  padding: 3px;
  aspect-ratio: unset;
  min-width: 2rem;
  border: none;
  font-size: 0.8rem;
}

.ques-comment {
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  font-weight: 500;
}

.ques-comment span {
  padding-bottom: 0.5rem;
}

.ques-comment span.comment {
  font-size: 0.85rem;
  color: #4d4d4d;
  max-width: 38rem;
  border-top: 1px solid #4d4d4d25;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  width: 100%;
}

.ques-comment span.comment span {
  font-size: 0.85rem;
  color: #ca353c;
}

/* CHART RELATED */

.langchart {
  height: 244px;
}

.chartlegends {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 20px;
  margin-left: 7px;
}
.chartlegends p {
  position: relative;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 12px;
  cursor: pointer;
}
.chartlegends p.language span {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  content: "";
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.chartlegends p::after {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  background-color: #ca373c;
  content: "";
  position: absolute;
  left: 0%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.chartlegends p:last-child::after {
  background-color: #333333;
}

.chartlegends p.language::after {
  content: none;
}
.chartlegends p.language:last-child::after {
  content: none;
}

.cardbox {
  background-color: #fff;
  border-radius: 5px;
  padding: 1rem;
}

.cardbox h5 {
  font-size: 1.05rem;
  font-weight: 600;
}

.chart-legends p {
  font-size: 0.9rem;
  font-weight: 500;
  margin: 0;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 10px;
  position: relative;
}

.chart-legends p span {
}

.chart-legends p span,
.chartlegends p:after {
  border-radius: 3px;
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 12px;
}

.machine-chart {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.apexcharts-legend-text {
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  margin: 0;
  cursor: pointer;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 10px;
  position: relative;
  font-family: "Poppins", sans-serif !important;
}

.cardbox .p-dropdown,
.cardbox .p-dropdown:hover,
.cardbox .p-dropdown:focus,
.cardbox .p-dropdown:focus-visible,
.cardbox .p-dropdown:focus-within {
  border: 1px solid #4d4d4d20;
}

.row > * {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

p.group-info {
  display: flex;
  gap: 0.5rem;
  font-weight: 500;
  font-size: 0.9rem;
}

p.group-info span {
  color: #ca353c;
}

.tabsproduct.toptab {
  width: 100%;
  border-color: #e3e2e2;
  position: fixed;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ccc;
}

.tabsproduct {
  z-index: 8;
  display: inline-flex;
}
.tabsproduct.updated .nav-item {
  display: flex;
  align-items: end;
  margin-left: 15px;
}
.tabsproduct.updated .nav-item .nav-link {
  border-bottom: 1px solid transparent;
  color: #333;
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-top: 10px;
  padding: 5px 15px;
}
.tabsproduct.updated .nav-item .nav-link:hover {
  background-color: rgba(202, 55, 60, 0.062745098);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.tabsproduct.updated .nav-item .nav-link:hover::after {
  content: none;
}
.tabsproduct.updated .nav-item .nav-link.active {
  background-color: #ca373c;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.3px;
  padding: 5px 15px;
}
.tabsproduct.updated .nav-item .nav-link.active::after {
  content: none;
}
.tabsproduct .nav-item .nav-link {
  color: #9b9b9b;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  border: none;
  border-radius: 0px;
  margin: 0px;
  padding: 10px 20px;
}
.tabsproduct .nav-item .nav-link:hover {
  border: none;
  border-bottom: 1px solid #ca373c;
}
.tabsproduct .nav-item .nav-link:hover::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #ca373c;
}
.tabsproduct .nav-item .nav-link.active,
.tabsproduct .nav-item .show > .nav-link {
  background-color: transparent;
  color: #ca373c;
  position: relative;
  font-weight: 500;
  border: none;
  border-bottom: 1px solid #ca373c;
  border-radius: 0px;
  padding: 10px 20px;
}
.tabsproduct .nav-item .nav-link.active::after,
.tabsproduct .nav-item .show > .nav-link::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #ca373c;
}

/* GORUPS TAB CSS STARTS */

.courses-parent {
  overflow-y: scroll;
  /* min-height: calc(100vh - 170px); */
  max-height: calc(100vh - 170px);
  margin-top: 10px;
  padding: 0 10px;
}

.courses-parent::-webkit-scrollbar {
  display: none;
}

.explore-courses .courses-parent {
  min-height: auto;
  max-height: inherit;
  overflow-y: initial;
}

.course-tiles {
  display: grid;
  gap: 1rem;
  --u_rg: 1.5rem;
  --u_cg: 1.5rem;
  grid-template-columns: repeat(var(--cols), 1fr);
  justify-items: stretch;
  justify-content: center;
  align-content: center;
  --cols: 1;
}

.course-tiles .tilecard {
  max-width: 260px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.usergrouplist .course-tiles .tilecard .groupcardesign,
.usergrouplist .course-tiles .tilecard .usercardesign {
  height: 100%;
  margin: 0;
  aspect-ratio: 1/1;
}

.groupcardesign,
.usercardesign {
  text-align: center;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 200px;
  border-radius: 5px;
  border: 1px solid #fff;
  cursor: pointer;
  transition: 0.3s;
  margin-bottom: 15px;
}
.groupcardesign:hover .avatarimg,
.groupcardesign:hover .initials-placeholder,
.usercardesign:hover .avatarimg,
.usercardesign:hover .initials-placeholder {
  background-color: #ccc;
}
.groupcardesign:hover .viewdetails svg,
.usercardesign:hover .viewdetails svg {
  animation: moveForward 0.3s forwards;
  animation-timing-function: ease-out;
}
.groupcardesign .groupname,
.usercardesign .groupname {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
  text-overflow: ellipsis;
  margin: 0;
  color: #333;
  width: 85%;
  padding: 10px 0;
}
.groupcardesign p,
.usercardesign p {
  font-size: 13px;
  margin-bottom: 0;
  text-align: center;
  color: #66666e;
}
.groupcardesign .playcount,
.usercardesign .playcount {
  font-size: 20px;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  color: #414141;
  margin-bottom: 0px;
  line-height: 1.2;
}
.groupcardesign .coursecompleted,
.usercardesign .coursecompleted {
  font-size: 14px;
  color: #1a1a1a;
}
.groupcardesign .viewdetails,
.usercardesign .viewdetails {
  align-items: center;
  background: #ca353b;
  border: 1px solid transparent;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 11px;
  justify-content: center;
  margin: 10px auto 0;
  padding: 5px 7px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: color 0.3s;
}
.groupcardesign .viewdetails svg,
.usercardesign .viewdetails svg {
  width: 0.45em;
  height: 0.45em;
  margin-left: 5px;
  display: inline-block;
  transition: transform 0.3s;
  margin-top: -2px;
}
.groupcardesign .groupusercount,
.usercardesign .groupusercount {
  display: flex;
  justify-content: center;
}
.groupcardesign .avatarimg,
.usercardesign .avatarimg {
  background: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  height: 65px;
  max-width: 65px;
  outline: 1px solid rgba(44, 44, 44, 0.314) !important;
  outline-offset: 0px;
  overflow: hidden;
  padding: 6px;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.groupcardesign .avatarimg img,
.usercardesign .avatarimg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 0px;
}
.groupcardesign .initials-placeholder,
.usercardesign .initials-placeholder {
  background: rgba(223, 225, 235, 0.4);
  border-radius: 50%;
  height: 70px;
  width: 70px;
  max-height: 70px;
  max-width: 70px;
  outline: 1px solid rgba(44, 44, 44, 0.314) !important;
  outline-offset: 0px;
  overflow: hidden;
  padding: 3px;
  position: relative;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.groupcardesign .initials-placeholder img,
.usercardesign .initials-placeholder img {
  width: 50px;
  height: 50px;
  line-height: 50px;
  -o-object-fit: contain;
  object-fit: contain;
  padding: 3px;
}

.groupname {
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: normal;
  text-overflow: ellipsis;
  margin: 0;
  color: #333;
  width: 85%;
  padding: 10px 0;
}

.groupusercount {
  display: flex;
  justify-content: center;
}

.video-react {
  padding-top: 0 !important;
  height: 100% !important;
}

.prime-select .p-multiselect-label,
.prime-select .p-dropdown-label {
  padding: 0.53rem;
  font-size: 14px;
}

.prime-select {
  position: relative;
}

.prime-select .p-multiselect-label {
  padding-right: 1rem;
}

/* .prime-select .p-multiselect-label{
  width: 12rem;   
} */

.p-multiselect-panel .p-multiselect-items,
.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.prime-select .p-multiselect-label,
.prime-select .p-dropdown-label {
  padding: 0.53rem;
  font-size: 14px;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ca373c;
  background: #ca373c20;
}

.p-checkbox.p-highlight .p-checkbox-box {
  border-color: #ca373c;
  background: #ca373c;
}

.p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
  border-color: #ca373c50;
}

.p-multiselect:not(.p-disabled):hover,
.p-multiselect:not(.p-disabled).p-focus,
.p-dropdown:not(.p-disabled):hover,
.p-dropdown:not(.p-disabled).p-focus {
  border-color: #ca373c50;
  box-shadow: 0 0 0 0.2rem #ca373c40;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  font-size: 14px;
}

.p-checkbox {
  width: 20px;
  height: 20px;
}

.p-inputtext:enabled:hover {
  border-color: #ca373c;
}

.p-inputtext:enabled:focus {
  border-color: #ca373c;
  box-shadow: 0 0 0 0.2rem #ca373c40;
}

.p-inputtext {
  padding: 0.5rem 0.5rem;
  font-size: 0.85rem;
}

.dselect {
  max-width: 23px;
  max-height: 23px;
  padding: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  right: 2rem;
  cursor: pointer;
  /* border: 1px solid #33333350; */
  background-color: #00000000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  background-color: #00000010;
}

.dselect:hover {
  background-color: #00000030;
}

.p-multiselect-label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 17rem;
}

.prime-select .p-multiselect-label {
  max-width: 20rem;
  min-width: 15rem;
  padding-right: 1.8rem;
}

.p-multiselect .p-multiselect-trigger {
  width: 3rem;
}

.showoverlay {
  background-color: #00000070;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9;
}

.daterangepickerf {
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
}

.rdrDateRangePickerWrapper {
  display: inline-flex;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.DefaultRedBtn {
  border: 1px solid #ca373c;
  border-radius: 5px;
  background: #ca373c;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 7px 15px;
  font-weight: 400;
  font-size: 0.9rem;
  height: 35px;
}

.rdrInRange,
.rdrDay,
.rdrStartEdge,
.rdrDayNumber,
.rdrEndEdge,
.rdrStaticRangeLabel,
.rdrDateDisplay,
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  color: #ca373c !important;
}

.UserBox {
  background-color: #fff;
  border: 1.5px solid #d3d3d3;
  border-radius: 5px;
  box-shadow: 0 0 20px -18px;
  margin-top: 10px;
  min-height: 125px;
  padding: 15px;
  z-index: 1;
}

.rolelist {
  flex-wrap: wrap;
  gap: 15px;
  justify-content: start;
  z-index: 1;
}

.rolelist,
.userselect {
  align-items: center;
  display: flex;
}

.userselect {
  background-color: #e8e8ff;
  border-radius: 15px;
  font-size: 12px;
  height: 25px;
  justify-content: center;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 999;
}

.userselect .sm-initials,
.userselect img {
  border-radius: 50%;
  height: 25px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 25px;
}

.userselect .sm-initials,
.userselect img {
  border-radius: 50%;
  height: 25px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 25px;
}

.userselect .sm-initials {
  background: #505050;
  color: #fff;
  font-size: 13px;
  font-weight: 400;
}

.removeuser,
.userselect .sm-initials {
  align-items: center;
  display: flex;
  justify-content: center;
}

.quesformbox p {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}

.userselect {
  background-color: #e8e8ff;
  border-radius: 15px;
  font-size: 12px;
  height: 25px;
  justify-content: center;
  max-width: -moz-fit-content;
  max-width: -webkit-fit-content;
  max-width: fit-content;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  z-index: 999;
}

.userselect.withinput {
  padding: 0 !important;
  position: relative;
}

.userselect.withinput .userinputsearch {
  width: auto;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  position: absolute;
  top: 38px;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  z-index: 9999;
}

.userinputsearch::-webkit-scrollbar-track,
.UserBox::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #ffffff;
  border-radius: 10px;
}

.userinputsearch::-webkit-scrollbar,
.UserBox::-webkit-scrollbar {
  width: 5px;
  background-color: #ffffff;
  display: block;
}

.userinputsearch::-webkit-scrollbar-thumb,
.UserBox::-webkit-scrollbar-thumb {
  background: transparent linear-gradient(91deg, #333333 0%, #767676 42%, #767676 53%, #333333 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 6px;
  box-shadow: 0px 3px 6px #00000029;
  width: 10px;
}

.userselect.withinput .userinputsearch {
  width: auto;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  min-width: 200px;
  position: absolute;
  top: 38px;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 10px;
  padding: 10px;
  overflow-y: scroll;
  z-index: 5;
}

.morehead {
  position: relative;
}

.morehead .userinputsearch {
  width: auto;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  min-width: 200px;
  position: absolute;
  top: 38px;
  left: 0;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 10px;
  padding: 10px;
  overflow-y: hidden;
  z-index: 5;
}

.morehead .userinputsearch .listbody {
  overflow-y: scroll;
}

.userserachitem {
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 5px;
  padding: 5px 15px 5px 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-weight: 500;
  z-index: 5;
}

.userserachitem:hover {
  background-color: #ca373c60;
}

.userserachitem img {
  position: relative;
  width: 27px;
  height: 27px;
}

.userserachitem .sm-initials {
  position: relative !important;
  left: 0;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  overflow: hidden;
  background: #505050;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 13px;
  color: #fff;
}

.userserachitem p {
  margin: 0;
  font-size: 13px;
}

.removeuser {
  background: #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: 5px;
  width: 20px;
}

.removeuser svg {
  fill: #000;
  width: 0.8em;
}

.delete-btn {
  width: 2rem;
  aspect-ratio: 1/1;
  display: block;
  background-color: #ca353b20;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: 200ms all;
}
.delete-btn svg {
  width: 100%;
  height: 100%;
}

.delete-btn:hover {
  transition: 200ms all;
  background-color: #ca353b50;
}

.delete-btn.disabled {
  opacity: 0.8;
  filter: grayscale(1);
  cursor: not-allowed;
}
